<template>
  <docs-layout>
    <asom-link href="#">Link example</asom-link>
  </docs-layout>
</template>
<script>
import DocsLayout from '@/docs/DocsLayout'
export default {
  components: {
    DocsLayout,
  }
}
</script>